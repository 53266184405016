@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans',
    sans-serif;
  overflow: hidden;
  color: #EEEEEE;
  overflow: auto;
  overflow-x: hidden !important;
  height: 100%;
}



/* Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 30%;
}


.icon-list-item {
  display: flex;
  gap: 20px;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}




/* Map legends */

.map-legends {
  border-radius: 0px !important;

  background: #00b153;
    padding: 12px 24px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
  /* Width of the square */
 
  color:white;
  /* Height of the square */
  background-color: #03b053;
  /* Light grey background, change as needed */
  /* Optional border */
}

/* Read More Button */

.read-more-btn {
  text-decoration: underline;
  font-size: 14px;
  text-transform: uppercase;
  color:#00b153;
  font-weight: 700;
}

/* filter header */
button.filterHeaderBtnToggle {
  background: #03b053;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  float: right;
  padding: 6px;
  padding-left: 25px;
  padding-right: 25px;
  bottom: 0px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.filterHeaderbtnWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
}

/* change default scroll bar */

/* width */
::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #262626bd !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000b3 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}



/* Google Map Full Screen Force Hide*/

button.gm-control-active.gm-fullscreen-control {
  display: none !important;
}



.capitalize {
  text-transform: capitalize;
}

.atf-brand-color {
  accent-color: #1d9851 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  font-family: 'Open Sans',
    sans-serif;
}

p {
  font-family: 'Inter',
    sans-serif;
}


/* select field customization */

.css-1s2u09g-control {
  border-radius: 0px !important;
}


select option {
  margin: 40px;
  background: #1d9851 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.header {
  padding: 0px;
  background-color: #eeeeee;
  color: #fff;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.header h1 {
  font-size: 25px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: 'Cormorant Garamond', serif;
}

.site-title {
  font-family: 'Cormorant Garamond',
    serif;
}

.map {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.location-icon {
  font-size: 2rem;
  color: #4191fa;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loader img {
  width: 400px;
}

.loader h1 {
  margin-top: -100px;
}

.location-info {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 435px;
  height: 644px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.80);
  font-size: 14px !important;
  color: #fff;
  overflow: auto;
  z-index: 9999 !important;
}

.location-info img {
  width: 100%;
}

.location-header-elements {
  display: flex;
  margin-bottom: 10px;
}

.location-info-header {
  font-size: 16px;
  font-weight: 600;
}

button.infoCloseButton {
  float: right;
  position: absolute;
  right: 25px;
  z-index: 999;
}

.location-info ul {
  list-style-type: none;
  padding: 0;
}

.location-info li {
  padding: 5px 0;
}


/* location button */

.atf-location-details-btn {
  background: #00b153;
  padding: 12px 24px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

/* location filter */

.atf-map-filter {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.atf-map-filter div {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}



/* Business Single Page */


.related-business-section-wrapper {
  width: 100%;
}

.item {
  width: 280px !important;
}

img.business-related-featured-image {
  height: 200px;
  width: 100%;
}


.business-main-wapper {
  margin-top: 200px;
  padding: 50px 100px;
}

.business-info-wrapper {
  background-color: #EEEEEE;
  min-height: 100vh;
}




.overlapping-image {
  position: absolute;
  top: 140px;
  max-width: 70%;
  left: 20%;
  right: 20%;
  margin-bottom: 100px;
}

.business-featured-image {
  margin: 0 auto;
  width: auto;
  height: 400px;
}



.business-info-inner-wrapper {
  margin-top: 30px;
  margin-bottom: 40px;
}


.listing-title {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 600;
  color: #444444;
  font-size: 44px;
  line-height: 1.3em;
}

.business-category {
  font-family: 'Cormorant Garamond',
    serif;
  font-weight: 600;
  color: #444444 !important;
  font-size: 26px;
}


.business-blocks {
  background-color: #fffefe;
  border: 1px solid #eeeeee;
  padding: 30px;
}

.business-blocks-min-height {
  min-height: 100px;
}

.business-second-image {
  width: 100%;
}

.business-block-b-name {
  font-family: 'Cormorant Garamond',
    serif;
  font-weight: 600;
  color: #444444 !important;
  font-size: 36px;
}

p.business-block-b-details {
  font-size: 18px;
  line-height: 1.7em;
  color: #444444;
}


.business-detials-btn {
  background: #00b153;
  padding: 12px 24px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
}

.b-details-btn-text {
  font-size: 18px;
  line-height: 1.7em;
  color: #444444;
}


/* Related business section starts */

.related-business-wrapper {
  width: 100%;
  flex-wrap: wrap;
}

.related-business-section-title {
  font-family: 'Cormorant Garamond',
    serif;
  font-weight: 600;
  color: #444444;
  font-size: 36px;

}



.related-card-box {
  background-color: #fffefe;
  border: 1px solid #eeeeee;
  padding: 30px;
}

.related-business-title {
  font-family: 'Cormorant Garamond',
    serif;
  font-weight: 600;
  color: #444444;
  font-size: 26px;

}

.b-related-details-btn-text {

  font-size: 16px;
  line-height: 1.7em;
  color: #444444;
}


/* Responsive CSS */


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  /* CSS */

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */

}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */


  .location-info {
    position: absolute;
    top: 70px;
    right: 63px;
    width: 435px;
    height: 644px;
    padding: 30px;
    background-color: green;
    font-size: 14px !important;
    color: #fff;
    overflow: auto;
  }



}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */

  .location-info {


    width: 96%;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 0px;
    right: 0px;
    height: auto;
    min-height: 400px;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.85);
    font-size: 14px !important;
    color: #fff;
    overflow-x: auto;
    z-index: 999;
    margin: 0 auto;
  }


  .business-main-wapper {
    margin-top: 0px;
    padding: 10px 30px;
    padding-bottom: 50px;
  }


  .related-business-section-title {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 600;
    color: #444444;
    font-size: 28px;
    line-height: 1.3em;
    margin-left: 20px;
    margin-right: 20px;
  }

  .top-left-cta {
    margin-top: 20px;
    margin-bottom: 20px;
  }



}



@media (min-width: 1536px) {

  .container {
    max-width: 2000px !important;
  }

}


/* Media query for mobile devices */
@media (max-width: 600px) {
  .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }
}